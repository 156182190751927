import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

const About = ({
  data: {
    site: { siteMetadata },
  },
}) => {
  const _title = `${siteMetadata.title} | about`
  return (
    <Layout>
      <Helmet>
        <title>{siteMetadata.title} | about</title>
        <meta name="twitter:title" content={_title} />
        <meta property="og:title" content={_title} />
      </Helmet>
      <article
        style={{
          padding: '1.45rem 1.0875rem',
          width: '100%',
          maxWidth: 960,
          margin: '0 auto',
        }}
      >
        <h1 style={{ color: '#4b4b4b' }}>About</h1>
        <h3>Background</h3>
        <p>
          I am originally from San Diego, CA where I attended MiraCosta
          Community College from 2011 - 2014. I continued my studies at the
          University of California - Davis from 2014 to 2016, where I graduated
          with a Bachelor of Science in Applied Mathematics. Upon graduating, I
          took Udacity's Full Stack Nanodegree where I got my first exposure to
          Web Development. I completed the course within two months and kept up
          my studies through other online platforms like freeCodeCamp and
          tylermcginnis.com, and I even completed a second Nanodegree on Udacity
          - the React Nanodegree.
        </p>
        <h3>Current</h3>
        <p>
          I am currently working as a {siteMetadata.job} for{' '}
          <a
            href={siteMetadata.currentCompany.website}
            rel="noopener noreferrer"
            target="_BLANK"
          >
            {siteMetadata.currentCompany.name}
          </a>
          !
        </p>
        <h3>Future</h3>
        <p>
          I started my career in Web Development because I had an idea for a
          start-up company but, I didn't know how to build it. In the next five
          years, I am hoping to pursue my idea and begin my own company after I
          get some industry experience. I also hope to speak at conferences and
          become a mentor to Junior Developers.
        </p>
        <Link to="/">Go back to the homepage</Link>
      </article>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        job
        currentCompany {
          name
          website
        }
      }
    }
  }
`
